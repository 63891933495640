import React from "react"
import { PageWrapper } from "~components"
import HeaderButton from '~sections/project/Header'
//import { Link } from "gatsby";
import CtaSection from "~sections/marketing/Cta"
import FooterOne from "~sections/marketing/FooterOne"
import BountySection from "~sections/bounty/BountySection"
//import scrollTo from 'gatsby-plugin-smoothscroll';
const header = {
  headerClasses: "site-header site-header--menu-start light-header site-header--sticky",
  containerFluid:false,
  buttonBlock: (
    <HeaderButton
      className="ms-auto d-none d-xs-inline-flex"
      btnOneText="Login"
      btnTwoText="14 day free trial"
      mr="15px"
      mrLG="0"
    />
  ),
}
const PageCmp = ({ data }) => {
  
  useEffect(() => {
      window.scrollTo(0,0)
  }, [data])
  
}
export default function Bounty() {
  return (
    <PageWrapper headerConfig={header}>
    <BountySection />
      <CtaSection />
      <FooterOne/>
    </PageWrapper>
  )
}
